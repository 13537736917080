<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="120px" @keyup.enter.native="search(1)">
            <el-form-item prop="borrowCodeNumber" label="借阅单号：">
                <el-input v-model.trim="searchForm.borrowCodeNumber" maxlength="50" placeholder="请输入借阅单号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="borrowUserName" label="借阅人：">
                <el-select v-model="searchForm.borrowUserName" placeholder="请选择借阅人" clearable class="w100i">
                    <el-option
                            :key="index"
                            :label="item"
                            :value="item"
                            v-for="(item, index) in borrowUserNameOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="borrowPurpose" label="借阅目的：">
                <el-select v-model="searchForm.borrowPurpose" placeholder="请选择借阅目的" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="createBy" label="借阅经办人：">
                <el-select v-model="searchForm.createBy" placeholder="请选择借阅经办人" clearable class="w100i">
                    <el-option
                            :key="index"
                            :label="item.name"
                            :value="item.userId"
                            v-for="(item, index) in createByOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="returnState" label="状态：">
                <el-select v-model="searchForm.returnState" placeholder="请选择状态" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('book_borrow_return')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="startLendTime" label="借出日期：" class="returnDate">
                <el-date-picker class="w100i"
                        v-model="lendTime"
                        type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="startDeadline" label="预计归还日期：" class="returnDate">
                <el-date-picker class="w100i"
                                v-model="deadline"
                                type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="startReturnTime" label="归还时间：" class="returnTime">
                <el-date-picker class="w100i"
                        v-model="returnTime"
                        type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="bookName" label="输入搜索：">
                <el-input v-model.trim="searchForm.bookName" maxlength="100" placeholder="请输入书名（限100字）"
                          clearable></el-input>
            </el-form-item>
            <div class="flex_b_c p_b2">
                <div>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-right" @click="reset()">重置</el-button>
                </div>
                <div>
                    <el-button size="small" type="primary" icon="el-icon-plus" v-show="hasPermission('borrowList:add')"
                               @click="addEdit(null, 'add')">登记
                    </el-button>
                </div>
            </div>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 450px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable">
                <el-table-column type="selection" fixed/>
                <el-table-column prop="borrowCodeNumber" label="借阅单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowUserName" label="借阅人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowPurpose" label="借阅目的" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("borrow_purpose", scope.row.borrowPurpose ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="lendTime" label="借出日期" show-overflow-tooltip></el-table-column>
                <el-table-column prop="deadline" label="预计归还日期" width="130" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowAmount" label="借阅数量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createName" label="借阅经办人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="returnState" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("book_borrow_return", scope.row.returnState ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="returnTime" label="归还时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('borrowList:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-show="hasPermission('borrowList:return') && scope.row.returnState == 1"
                                   @click="returnData(scope.row)">归还
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('borrowList:edit') && scope.row.returnState == 1"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <div class="totalInfo">共 <span>{{total}}</span> 条记录，借阅图书 <span>{{applyTotal}}</span> 次</div>
        </div>
        <BorrowForm ref="borrowForm" @refreshDataList="search()"></BorrowForm>
    </div>
</template>

<script>
    import BorrowForm from './borrowForm'

    export default {
        components: {BorrowForm},
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getFullYear() < 1940 || time.getFullYear() > new Date().getFullYear()
                    }
                },
                borrowUserNameOpt: [],
                createByOpt: [],
                searchForm: {
                    borrowCodeNumber: '',
                    borrowUserName: '',
                    borrowPurpose: '',
                    createBy: '',
                    returnState: '',
                    startLendTime: '',
                    endLendTime: '',
                    startDeadline: '',
                    endDeadline: '',
                    startReturnTime: '',
                    endReturnTime: '',
                    bookName: '',
                    current: 1,
                    size: 10,
                },
                lendTime: [],
                deadline: [],
                returnTime: [],
                loading: false,
                dataList: [],
                dataListSelections: [],
                total: 0,
                applyTotal: 0,
            }
        },
        watch: {
            "lendTime"(newVal) {
                if (newVal == null) {
                    this.lendTime = [];
                }
            },
        },
        mounted() {
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.findBorrowUserAndCreateBy()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.startLendTime = this.lendTime ? this.lendTime[0] : ''
                p.endLendTime = this.lendTime ? this.lendTime[1] : ''
                p.startDeadline = this.deadline ? this.deadline[0] : ''
                p.endDeadline = this.deadline ? this.deadline[1] : ''
                p.startReturnTime = this.returnTime ? this.returnTime[0] : ''
                p.endReturnTime = this.returnTime ? this.returnTime[1] : ''
                this.getTotal(p)
                this.$axios(this.api.record.bookborrowList, p, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            getTotal(p) {
                this.$axios(this.api.record.borrowAmountTotal, p, 'post').then((res) => {
                    if (res.data && res.status) {
                        this.applyTotal = res.data
                    }
                })
            },
            // 获取出版社
            findBorrowUserAndCreateBy() {
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.startLendTime = this.lendTime ? this.lendTime[0] : ''
                p.endLendTime = this.lendTime ? this.lendTime[1] : ''
                p.startDeadline = this.deadline ? this.deadline[0] : ''
                p.endDeadline = this.deadline ? this.deadline[1] : ''
                p.startReturnTime = this.returnTime ? this.returnTime[0] : ''
                p.endReturnTime = this.returnTime ? this.returnTime[1] : ''
                this.$axios(this.api.record.findBorrowUserAndCreateBy, p, 'post').then(data => {
                    if (data.status) {
                        this.borrowUserNameOpt = data.data.borrowUserNameList
                        this.createByOpt = data.data.createByList
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.borrowForm.init(row, method)
            },
            // 归还
            returnData(item) {
                this.$confirm(`确定归还本次借出的全部图书？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.returnBorrow, {id: item.id, returnState: '0'}).then(data => {
                        if (data.status) {
                            this.$message.success('归还成功')
                            this.search()
                        } else {
                            this.$message.success('归还失败')
                        }
                    })
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 重置
            reset() {
                this.searchForm.endLendTime = ''
                this.searchForm.endDeadline = ''
                this.searchForm.endReturnTime = ''
                this.lendTime = []
                this.deadline = []
                this.returnTime = []
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }

        .el-form-item--small.el-form-item.returnDate {
            width: 37.5%;
        }

        .el-form-item--small.el-form-item.returnTime {
            width: 45%;
        }
    }
</style>
